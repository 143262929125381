import React, { Fragment } from 'react';
import Section from '../Atoms/Section';
import Container from '../Atoms/Container';
import Column from '../Atoms/Column';
import Paragraph from '../Atoms/Paragraph';
import Row from '../Atoms/Row';

function LeftTitleRightDesc({
  title, desc, minHeight = 'auto', ...rest
}) {
  const descArr = desc.split(/\r?\n/);

  return (
    <Section minHeight={minHeight} {...rest}>
      <Container>
        <Row>
          <Column col="is-3" pb={[0, 0, 3]}>
            {title && title !== '' && <Paragraph pb={0} fontSize={2} fontWeight={600}>{title}</Paragraph>}
          </Column>
          <Column>
            {descArr && descArr.map((sub, i) => (
              <Fragment key={i}>
                {!sub && <Paragraph width="100%" />}
                {sub && sub !== '' && <Paragraph width="100%" pb={0}>{sub}</Paragraph>}
              </Fragment>
            ))}
          </Column>
        </Row>
      </Container>
    </Section>
  );
}

export default LeftTitleRightDesc;
