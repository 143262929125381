import React from 'react';
import { Link, graphql } from 'gatsby';

import { FormattedMessage } from 'react-intl';
import { PageHelmet } from '../components/PageHelmet';
import PageHeader from '../components/PageHeader';
import LeftTitleRightDesc from '../components/SectionBlocks/LeftTitleRightDesc';
import RepeatableSectionSelector from '../components/SectionBlocks/RepeatableSectionSelector';
import LeftTitleRightHeadline from '../components/SectionBlocks/LeftTitleRightHeadline';
import HighlightListWithIcons from '../components/SectionBlocks/HighlightListWithIcons';
import theme from '../styles/theme';
import CenterDesc from '../components/SectionBlocks/CenterDesc';

// ********************************* UI Template (Shared with CMS) *********************************
export const InnovationPageTemplate = ({
  title,
  description,
  bgImage,
  intro,
  sections,
  bgText,
  greenValues,
  sectionsOne,
  sectionsTwo,
  automation,
  sectionsAuto,
}) => (
  <div>
    <PageHelmet title={title} description={description} />
    <PageHeader
      title={<FormattedMessage id="innovation:title" defaultMessage="Innovation" />}
      subtitle={<FormattedMessage id="innovation:subtitle" defaultMessage="About" />}
      bgImage={bgImage}
    />
    <LeftTitleRightDesc {...intro} />
    {sections && <RepeatableSectionSelector sections={sections} />}
    <LeftTitleRightHeadline {...bgText} background={theme.color.blue} color={theme.color.white} py={[4, 4, 5]} />
    <HighlightListWithIcons {...greenValues} hasBg />
    {sectionsOne && <RepeatableSectionSelector sections={sectionsOne} />}
    {sectionsTwo && <RepeatableSectionSelector sections={sectionsTwo} />}
    <LeftTitleRightDesc {...automation} />
    {sectionsAuto && <RepeatableSectionSelector sections={sectionsAuto} />}
  </div>
);

// ********************************* Render page *********************************
const InnovationPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return <InnovationPageTemplate {...frontmatter} />;
};

export default InnovationPage;

// ********************************* Data graphql *********************************
export const pageQuery = graphql`
  query InnovationPageWithId($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
        bgImage {
          alt
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        intro {
          title
          desc
        }
        sections {
          title
          sectionType
          desc
          img {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 1024, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        bgText {
          title
          desc
        }
        greenValues {
          title
          list {
            title
            desc
          }
        }
        automation {
          title
          desc
        }
        sectionsAuto {
          title
          sectionType
          desc
          img {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 1024, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
