import React, { Component } from 'react';
import Section from '../../Atoms/Section';
import Container from '../../Atoms/Container';
import Column from '../../Atoms/Column';
import PreviewCompatibleImage from '../../Atoms/PreviewCompatibleImage';
import Heading from '../../Atoms/Heading';
import Paragraph from '../../Atoms/Paragraph';
import Box from '../../Atoms/Box';
import CustomLink from '../../Atoms/CustomLink';
import Row from '../../Atoms/Row';
import FlexBox from '../../Atoms/FlexBox';
import HighlightListWithIcons from '../HighlightListWithIcons';
import HighlightItem from '../HighlightItem';
import ReadMoreButton from '../../Rolls/ReadMoreButton';

class ImageTextSideBySide extends Component {
  render() {
    const {
      imageOnRight,
      points,
      title,
      desc,
      img,
      link,
      minHeight = 'auto',
      ...rest
    } = this.props;

    const descArr = desc.split(/\r?\n/);

    return (
      <Section minHeight={minHeight} py={0} {...rest}>
        <Container>
          <Row>
            <Column px={[0]} order={imageOnRight ? 0 : 2}>
              <FlexBox width="100%">
                <Box overflowHidden width="100%" maxWidth={['auto', 'auto', 'none']} maxHeight={[320, 320, 400, 400, 520]}>
                  <PreviewCompatibleImage imageInfo={img} />
                </Box>
              </FlexBox>
            </Column>
            <Column order={1} pt={[0, 0, 3]}>
              <FlexBox
                width="100%"
                height="100%"
                flexDirection="column"
                p={[0, 0, 3]}
                pb={[3, 3, 0]}
              >
                {points && (
                  <FlexBox width="100%" justifyContent="space-between" pb={3}>
                    {points.map(point => (
                      <HighlightItem {...point} />
                    ))}
                  </FlexBox>
                )}
                {title && (
                <Paragraph fontSize={2} fontWeight={600} width="100%">
                  {title}
                </Paragraph>
                )}
                {descArr && descArr.map(sub => (
                  <>
                    {!sub && <Paragraph width="100%" />}
                    {sub && sub !== '' && <Paragraph width="100%" pb={0}>{sub}</Paragraph>}
                  </>
                ))}
                {link && link.url !== '' && (
                  <Box mt={3} width="100%">
                    <CustomLink to={link.url}>
                      <ReadMoreButton text={link.text} />
                    </CustomLink>
                  </Box>
                )}
              </FlexBox>
            </Column>
          </Row>
        </Container>
      </Section>
    );
  }
}

export default ImageTextSideBySide;
