import React from 'react';

import ImageTextSideBySide from './RepeatableSections/ImageTextSideBySide';
import Box from '../Atoms/Box';

function RepeatableSectionSelector({ sections }) {
  const doms = [];

  sections.forEach((section, i) => {
    switch (section.sectionType) {
      case 'leftImageRightText':
        doms.push(<ImageTextSideBySide {...section} key={i} imageOnRight />);
        break;
      case 'rightImageLeftText':
        doms.push(<ImageTextSideBySide {...section} key={i} />);
        break;
      default:
        break;
    }
  });

  return <Box py={[4, 4, 5]}>{doms.map(dom => dom)}</Box>;
}

export default RepeatableSectionSelector;
