import React, { Fragment } from 'react';
import Section from '../Atoms/Section';
import Container from '../Atoms/Container';
import Column from '../Atoms/Column';
import Heading from '../Atoms/Heading';
import Paragraph from '../Atoms/Paragraph';
import Row from '../Atoms/Row';

function LeftTitleRightHeadline({
  title, desc, minHeight = 'auto',  ...rest
}) {
  return (
    <Section minHeight={minHeight} {...rest}>
      <Container>
        <Row>
          <Column col="is-3" pb={[0, 0, 3]}>
            {title && title !== '' && <Paragraph pb={0} fontSize={2} fontWeight={600}>{title}</Paragraph>}
          </Column>
          <Column>
            {desc && desc !== '' && <Heading pb={0}>{desc}</Heading>}
          </Column>
        </Row>
      </Container>
    </Section>
  );
}

export default LeftTitleRightHeadline;
